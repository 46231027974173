import React from "react";
import {gql, useQuery} from '@apollo/client';
import {Loading} from "./Loading";
import {OpenGraphLink} from "./OpenGraphLink";
import {get, KEYS} from "../services/Storage";

const POST_RELATED_LINKS = gql`
query postRelatedLinks($postUrl: String!) {
  postRelatedLinks(postUrl: $postUrl) 
}
`;

export const PostRelatedLink = ({igUrl}) => {
    const {loading, error, data} = useQuery(POST_RELATED_LINKS, {
        variables: {postUrl: igUrl},
    });

    if (loading) return <Loading/>;
    else if (error) {
        console.error(error.message);
    } else if (data && data.postRelatedLinks) {
        // Auto redirect link
        let autoRedirect = false;
        if (get(KEYS.AUTO_REDIRECT, true)) {
            autoRedirect = true;
            setTimeout(() => {
                window.location.replace(data.postRelatedLinks);
            }, 2000);
        }

        return (
            <div>
                <h5>{autoRedirect ? 'Redirecting to...' : 'Related link'}</h5>
                <OpenGraphLink url={data.postRelatedLinks}/>
                <br/>
            </div>
        );
    }
    return <></>
};
