import React from "react";
import {gql, useQuery} from '@apollo/client';
import {LinkGroup, Loading, PostHeader, PostRelatedLink} from "../components";
import {get, KEYS} from "../services/Storage";
import {SearchLink} from "./SearchLink";
import {useLocation} from "react-router-dom";

const POST_DETAILS = gql`
query postDetails($postUrl: String!) {
  postDetails(postUrl: $postUrl) {
    caption
    captionUrls
    imageUrl
    profile {
      bioUrl
      fullName
      userName
      profilePicUrl
    }
  }
}
`;

export const GetLink = ({igUrl}) => {
    const location = useLocation();
    if (!igUrl && location.pathname && location.pathname.includes('instagram')) {
        igUrl = location.pathname.substring(1) + location.search + location.hash;
    }

    const {loading, error, data} = useQuery(POST_DETAILS, {
        variables: {postUrl: igUrl},
    });

    if (!igUrl) {
        return <SearchLink/>
    }

    let body;
    if (loading) {
        body = <Loading/>;
    } else if (error) {
        body = <span className='text-neutral-secondary'>{error.message}</span>;
    } else if (data && data.postDetails && data.postDetails.profile) {
        const postDetails = data.postDetails;
        const relatedLinks = postDetails.profile.bioUrl ? <PostRelatedLink igUrl={igUrl}/> : <></>;

        // Auto redirect link
        let autoRedirect = false;
        if (postDetails.captionUrls && postDetails.captionUrls.length === 1 && get(KEYS.AUTO_REDIRECT, true)) {
            autoRedirect = true;
            setTimeout(() => {
                window.location.replace(postDetails.captionUrls[0]);
            }, 2000);
        }

        body = <div>
            <PostHeader postDetails={postDetails}/>
            {relatedLinks}
            <LinkGroup groupTitle={autoRedirect ? 'Redirecting to...' : 'Post caption links'}
                       urls={postDetails.captionUrls}/>
            <LinkGroup groupTitle="Bio Link" urls={[postDetails.profile.bioUrl]}/>
        </div>;
    } else {
        body = <p>Unable to get post information</p>
    }

    return (
        <div className="page-content">
            {body}
        </div>
    );
};
