import React from "react";
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import {Link} from "react-router-dom";
import {Logo} from "../assets/Icons";

const StyledNavbar = styled.nav`
    display: flex;
    padding: 0.6em;
    justify-content: center;  

    ${breakpoint('desktop')`
        justify-content: start;
    `}
`;

const StyledBrand = styled.span`
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-color-normal);
    
    a {
        text-decoration: none;
        
        h5 {
            margin-bottom: 0;
        }
    }
`;

const StyledLogo = styled.div`
    width: 4rem;
    height: 4rem;
    text-align: center;
    
    svg {
        width: 100%;
        height: 100%;
    }
`;

export const Navbar = () =>
    <StyledNavbar>
        <StyledBrand>
            <StyledLogo>
                <Logo/>
            </StyledLogo>
            <Link to="/">
                <h5>Gramaid</h5>
            </Link>
        </StyledBrand>
    </StyledNavbar>;
