import React from "react";
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const StyledHero = styled.section`
    max-width: 72rem;
    margin: 0 auto;
    padding: 5em 1em;
    ${breakpoint('desktop')`
        padding: 10em 5em;
    `}
`;

const StyledSubHeading = styled.h5`
    color: var(--accent-color);
    font-size: 1.4em;
    text-transform: uppercase;
    ${breakpoint('desktop')`
        font-size: 1.6em;
    `}
`;

const StyledHeading = styled.h1`
    font-weight: 700;
    line-height: 1em;
    margin-bottom: 1em;
`;

export const Hero = ({heading, subHeading, children}) =>
    <StyledHero className="hero">
        <StyledSubHeading>{subHeading}</StyledSubHeading>
        <StyledHeading className="display-1">{heading}</StyledHeading>
        <div>
            {children}
        </div>
    </StyledHero>;
