import React from "react";

export const Logo = props => (
    <svg
        width={565}
        height={565}
        viewBox="0 0 565 565"
        {...props}
    >
        <path fill="var(--accent-color)"
              d=" M 269.43 49.73 C 290.72 47.22 312.74 51.22 331.73 61.19 C 356.09 73.79 375.28 96.02 384.12 121.99 C 387.55 131.95 389.48 142.43 389.99 152.95 C 390.00 164.66 390.03 176.37 389.99 188.09 C 390.03 193.75 387.29 199.36 382.71 202.71 C 376.78 207.22 367.97 207.41 361.86 203.14 C 356.94 199.83 353.90 194.00 354.00 188.07 C 353.94 177.04 353.99 166.00 353.90 154.97 C 353.61 137.23 346.39 119.71 334.05 106.95 C 325.94 98.45 315.64 92.09 304.45 88.49 C 284.45 82.02 261.64 84.60 243.76 95.75 C 223.44 108.10 210.36 131.23 210.03 154.98 C 209.97 172.32 210.00 189.66 210.01 207.00 C 210.10 224.86 217.17 242.58 229.45 255.55 C 243.55 270.65 264.49 279.00 285.10 277.87 C 292.78 277.37 300.09 274.80 307.38 272.52 C 313.50 270.63 320.51 272.45 324.99 277.01 C 329.64 281.51 331.44 288.61 329.60 294.80 C 328.05 300.45 323.47 305.01 317.97 306.89 C 308.32 310.28 298.34 312.99 288.11 313.76 C 261.36 315.49 234.18 306.50 213.55 289.44 C 189.09 269.56 174.06 238.56 174.01 207.00 C 174.01 190.34 173.95 173.67 173.95 157.01 C 173.88 129.65 184.77 102.46 203.71 82.71 C 220.88 64.50 244.55 52.57 269.43 49.73 Z"/>
        <path fill="var(--accent-color)"
              d=" M 271.44 182.63 C 295.52 179.94 320.35 186.19 340.63 199.37 C 361.22 212.70 377.08 233.20 384.64 256.55 C 388.42 267.95 390.11 279.99 390.00 292.00 C 389.96 332.00 390.06 372.01 389.95 412.01 C 389.57 444.18 373.66 475.60 348.21 495.21 C 333.19 506.92 315.02 514.58 296.12 517.05 C 274.60 519.92 252.23 516.12 232.93 506.15 C 209.88 494.43 191.35 474.16 181.75 450.15 C 176.48 437.12 173.88 423.05 173.99 409.00 C 174.00 378.33 174.00 347.66 173.99 316.99 C 174.03 313.04 173.78 309.07 174.21 305.13 C 175.11 297.90 180.93 291.64 188.05 290.16 C 194.50 288.66 201.64 291.06 205.81 296.21 C 208.82 299.72 210.12 304.38 210.02 308.95 C 209.97 342.63 210.01 376.31 209.99 409.99 C 209.89 425.82 215.17 441.61 224.84 454.14 C 234.18 466.36 247.63 475.34 262.47 479.38 C 279.37 484.03 297.96 482.47 313.74 474.77 C 328.30 467.81 340.28 455.67 347.10 441.05 C 351.82 431.07 354.09 420.01 354.00 409.00 C 353.99 369.33 354.01 329.66 353.99 290.00 C 354.06 271.35 346.71 252.72 333.56 239.45 C 316.20 221.59 288.76 213.36 264.71 220.74 C 259.33 222.27 253.90 225.00 248.16 223.68 C 240.28 222.28 233.99 215.02 233.68 207.02 C 233.11 199.39 238.04 191.79 245.26 189.22 C 253.71 186.09 262.49 183.78 271.44 182.63 Z"/>

    </svg>
);

export const Home = props => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="var(--text-color-normal)"
        {...props}
    >
        <path fill="none" d="M0 0h24v24H0z"/>
        <path
            d="M19 21H5a1 1 0 01-1-1v-9H1l10.327-9.388a1 1 0 011.346 0L23 11h-3v9a1 1 0 01-1 1zm-6-2h5V9.157l-6-5.454-6 5.454V19h5v-6h2v6z"
        />
    </svg>
);

export const Settings = props => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="var(--text-color-normal)"
        {...props}
    >
        <path fill="none" d="M0 0h24v24H0z"/>
        <path
            d="M2 12c0-.865.11-1.703.316-2.504A3 3 0 0 0 4.99 4.867a9.99 9.99 0 0 1 4.335-2.505 3 3 0 0 0 5.348 0 9.99 9.99 0 0 1 4.335 2.505 3 3 0 0 0 2.675 4.63c.206.8.316 1.638.316 2.503 0 .865-.11 1.703-.316 2.504a3 3 0 0 0-2.675 4.629 9.99 9.99 0 0 1-4.335 2.505 3 3 0 0 0-5.348 0 9.99 9.99 0 0 1-4.335-2.505 3 3 0 0 0-2.675-4.63C2.11 13.704 2 12.866 2 12zm4.804 3c.63 1.091.81 2.346.564 3.524.408.29.842.541 1.297.75A4.993 4.993 0 0 1 12 18c1.26 0 2.438.471 3.335 1.274.455-.209.889-.46 1.297-.75A4.993 4.993 0 0 1 17.196 15a4.993 4.993 0 0 1 2.77-2.25 8.126 8.126 0 0 0 0-1.5A4.993 4.993 0 0 1 17.195 9a4.993 4.993 0 0 1-.564-3.524 7.989 7.989 0 0 0-1.297-.75A4.993 4.993 0 0 1 12 6a4.993 4.993 0 0 1-3.335-1.274 7.99 7.99 0 0 0-1.297.75A4.993 4.993 0 0 1 6.804 9a4.993 4.993 0 0 1-2.77 2.25 8.126 8.126 0 0 0 0 1.5A4.993 4.993 0 0 1 6.805 15zM12 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
    </svg>
);

export const Link = props => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="var(--text-color-normal)"
        {...props}
    >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0z"/>
            <path
                d="M18.364 15.536L16.95 14.12l1.414-1.414a5 5 0 1 0-7.071-7.071L9.879 7.05 8.464 5.636 9.88 4.222a7 7 0 0 1 9.9 9.9l-1.415 1.414zm-2.828 2.828l-1.415 1.414a7 7 0 0 1-9.9-9.9l1.415-1.414L7.05 9.88l-1.414 1.414a5 5 0 1 0 7.071 7.071l1.414-1.414 1.415 1.414zm-.708-10.607l1.415 1.415-7.071 7.07-1.415-1.414 7.071-7.07z"/>
        </svg>

    </svg>
);

export const Menu = props => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="var(--text-color-normal)"
        {...props}
    >
        <path fill="none" d="M0 0h24v24H0z"/>
        <path d="M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z"/>
    </svg>
);
