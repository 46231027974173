import React from "react";
import styled from 'styled-components';

const StyledLoadingContainer = styled.div`
    position: relative;
    min-height: 5em;
    
    @-webkit-keyframes spinner {
        0% {
            -webkit-transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(359deg);
        }
    }
    @keyframes spinner {
        0% {
            -webkit-transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(359deg);
        }
    }
    
    &:before {
        animation: spinner .6s infinite cubic-bezier(.49, .34, .97, .93);
        -webkit-animation: spinner .6s infinite cubic-bezier(.49, .34, .97, .93);
        width: 36px;
        height: 36px;
        position: absolute;
        z-index: 98;
        top: calc(50% - 18px);
        left: calc(50% - 18px);
        content: '';
        border-radius: 50%;
        border-top: 3px solid var(--accent-color);
    }
`;

export const Loading = () =>
    <StyledLoadingContainer>

    </StyledLoadingContainer>;
