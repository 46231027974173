import React from "react";
import {OpenGraphLink} from "./OpenGraphLink";

export const LinkGroup = ({groupTitle, urls}) => {
    if (urls && urls.length > 0 && urls[0]) {
        let body = urls.map((url) =>
            <OpenGraphLink url={url} key={url}/>
        );
        return (
            <div>
                <h5>{groupTitle}</h5>
                {body}
                <br/>
            </div>
        );
    } else {
        return <></>
    }
};
