import React from "react";
import {GetLink} from "./GetLink";

const SHARE_PARAMS = ["title", "text", "url", "name", "description", "link"];

export const Share = () => {
    const parsedUrl = new URL(window.location.toString());
    let sharedUrl = null;
    for (const shareParam of SHARE_PARAMS) {
        const shareValue = parsedUrl.searchParams.get(shareParam);
        if (shareValue !== null) {
            sharedUrl = shareValue;
        }
    }

    if (sharedUrl != null) {
        return <GetLink igUrl={sharedUrl}/>;
    }
};
