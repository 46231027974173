import React from "react";
import {gql, useQuery} from '@apollo/client';
import {Loading} from "./Loading";
import styled from 'styled-components';
import {decode} from 'html-entities';

const OPEN_GRAPH_URL = gql`
query openGraphUrl($url: String!) {
  openGraphUrl(url: $url) {
    title
    url
    imageUrl
    description
  }
}
`;

const StyledLinkCard = styled.div`
    border-radius: var(--border-radius);
    border: 1px solid var(--background-color-softer);
    overflow: hidden;
    display: flex;
    margin-bottom: 1rem;
    flex-direction: row;
`;

const StyledLinkImage = styled.div`
    height: auto;
    background: var(--background-color-softer);
    width: 100%;
    max-width: 6em;
    
    img {
        height: 100%;    
    }
`;

const StyledLinkTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 1em;
    justify-content: center;
    
    span {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    
    strong > span {
        -webkit-line-clamp: 1;
    }
`;
export const OpenGraphLink = ({url}) => {
    const {loading, error, data} = useQuery(OPEN_GRAPH_URL, {
        variables: {url: url},
    });

    if (loading) return <Loading/>;
    if (error) {
        console.error(error.message);
        return <a href={url}>{url}</a>
    }
    const ogData = data.openGraphUrl;

    return (
        <a href={url}>
            <StyledLinkCard>
                <StyledLinkImage>
                    <img src={ogData.imageUrl}/>
                </StyledLinkImage>
                <StyledLinkTextContainer>
                    <strong><span>{decode(ogData.title)}</span></strong>
                    <span className="text-neutral-secondary">{decode(ogData.description)}</span>
                </StyledLinkTextContainer>
            </StyledLinkCard>
        </a>
    );
};
