import {get, KEYS, set} from "./Storage";

export const setTheme = (isDark = null) => {
    if (isDark === null) {
        const prefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
        isDark = get(KEYS.DARK_THEME, null);
        if (isDark === null) {
            isDark = prefersDark;
        }
    }
    set(KEYS.DARK_THEME, isDark);
    document.body.className = `theme-${isDark ? 'dark' : 'light'}`;
}
