const storage = localStorage;
const KEYS = {
    AUTO_REDIRECT: 'auto.redirect',
    DARK_THEME: 'dark.theme',
};
Object.freeze(KEYS);

const set = (key, value) => {
    if (typeof value === 'string') {
        storage.setItem(`gramaid.${key}`, value);
    } else {
        storage.setItem(`gramaid.${key}`, JSON.stringify(value));
    }
}

const get = (key, defaultValue) => {
    const saved = storage.getItem(`gramaid.${key}`);
    if (!saved) {
        return defaultValue;
    }
    try {
        return JSON.parse(saved);
    } catch (e) {
        return saved;
    }
}

module.exports = {
    KEYS, get, set
}
