import React from "react";
import {Hero} from "../components";
import {Link} from "react-router-dom";
import styled from "styled-components";

const StyledCTA = styled.div`
    text-align: center;
    padding-top: 2em;
`;

export const Home = () => <div>
    <Hero heading="Clickable links on Instagram posts" subHeading="Introducing Gramaid">
        <h4 className="text-neutral-secondary">
            Finally, a way to navigate to a link inside an Instagram post.
            <span className="text-accent"> No signup required.</span>
        </h4>

        <StyledCTA>
            <Link to='/link' className='button'>
                Try now
            </Link>
        </StyledCTA>
    </Hero>
</div>;
