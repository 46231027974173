import React from "react";
import {Hero} from "../components";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";

const textInput = React.createRef();

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const SearchLink = () => {
    const navigate = useNavigate();
    return (
        <div>
            <Hero heading="View Instagram post links" subHeading="Engage with your feed">
                <h4 className="text-neutral-secondary">
                    Enter a post link below
                </h4>

                <StyledForm onSubmit={() => navigate("/" + textInput.current.value)}>
                    <input type='url' placeholder='https://www.instagram.com/p/CGsIlHSjPuW/'
                           pattern='https?:\/\/.*instagram.com.+' required ref={textInput}/>
                    <input type='submit' value={'Get Links'}/>
                </StyledForm>
            </Hero>
        </div>
    )
}
