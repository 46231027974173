import React from "react";
import './App.scss';
import {ApolloClient, ApolloProvider, InMemoryCache} from '@apollo/client';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {GetLink, Home, Settings, Share} from "./routes";
import {Footer, Navbar} from "./components";
import {setTheme} from "./services/Utils";

const apiOrigin = window.location.origin === 'http://localhost:3000' ?
    'https://cors-proxy.bitspice.workers.dev/https://gramaid.com' : window.location.origin;
const client = new ApolloClient({
    uri: `${apiOrigin}/api/`,
    cache: new InMemoryCache()
});

const App = () => {
    setTheme();
    return (
        <ApolloProvider client={client}>
            <BrowserRouter>
                <Navbar/>
                <div className="container">
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/share" element={<Share/>}/>
                        <Route path="/https://www.instagram.com/*" element={<GetLink/>}/>
                        <Route path="/link" element={<GetLink/>}/>
                        <Route path="/settings" element={<Settings/>}/>
                    </Routes>
                </div>
                <Footer/>
            </BrowserRouter>
        </ApolloProvider>
    )
};

export default App;
