import React from "react";
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const StyledProfilePic = styled.img`
    background: var(--background-color);
    border-style: solid;
    border-color: var(--background-color);
    border-width: 0.2em;
    border-radius: 50%;
    position: relative;
    width: 15vw;
    margin-top: calc(-19vw / 2);
    
    ${breakpoint('desktop')`
        border-width: 0.3em;
        width: 10vw;
        margin-top: calc(-12vw / 2);
    `}
`;

const StyledBanner = styled.div`
    height: 40vh;
    background-image: ${props => `url(${props.image})`};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
    &:after {
        content:'';
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        background: linear-gradient(180deg, var(--background-color) 0%, var(--accent-color) 100%);
        opacity: 0.5;
    }
    
    ${breakpoint('desktop')`
        max-height: 50vh;
    `}
`;

export const PostHeader = ({postDetails}) =>
    <div>
        <div className='break-out'>
            <StyledBanner image={`https://cors-proxy.bitspice.workers.dev/${postDetails.imageUrl}`}/>
        </div>
        <StyledProfilePic src={`https://cors-proxy.bitspice.workers.dev/${postDetails.profile.profilePicUrl}`}/>
        <div>
            <b>{postDetails.profile.fullName}</b>
            <p className="text-neutral-secondary">@{postDetails.profile.userName}</p>
        </div>
    </div>;
