import React, {useState} from "react";
import {get, KEYS, set} from "../services/Storage";
import {setTheme} from "../services/Utils";
import styled from "styled-components";

export const Settings = () => {
    const [settings, setSettings] = useState({
        [KEYS.AUTO_REDIRECT]: get(KEYS.AUTO_REDIRECT, true),
        [KEYS.DARK_THEME]: get(KEYS.DARK_THEME, false)
    });

    const handleInputChange = (e) => {
        const inputName = e.target.name;
        const inputValue = e.target.type === "checkbox" ? e.target.checked : e.target.value;
        setSettings({...settings, [inputName]: inputValue});
        set(inputName, inputValue);

        switch (inputName) {
            case KEYS.DARK_THEME:
                setTheme(inputValue);
                break;
        }
    }

    const StyledFormSection = styled.div`
        padding: 0.5em 1em;
        background: var(--background-color-soft);
        border-radius: var(--border-radius);
    `;

    return (
        <div>
            <h5>Settings</h5>

            <StyledFormSection>
                <div className='form-input'>
                    <label>Auto redirect links</label>
                    <label className="toggle">
                        <input type="checkbox" name={KEYS.AUTO_REDIRECT} checked={settings[KEYS.AUTO_REDIRECT]}
                               onChange={handleInputChange}/>
                        <span className="slider"/>
                    </label>
                </div>

                <div className='form-input'>
                    <label>Dark theme</label>
                    <label className="toggle">
                        <input type="checkbox" name={KEYS.DARK_THEME} checked={settings[KEYS.DARK_THEME]}
                               onChange={handleInputChange}/>
                        <span className="slider"/>
                    </label>
                </div>
            </StyledFormSection>
        </div>
    )
}
