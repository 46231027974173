import React from "react";
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import {FooterTab} from "./FooterTab";
import {Home, Link, Settings} from "../assets/Icons";

const StyledFooter = styled.footer`
    background: var(--background-color-softer);
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-around;
    
    a {
        display: flex;
    }
    
    ${breakpoint('tablet')`
         justify-content: center;
    `}
`;

export const Footer = () =>
    <StyledFooter>
        <FooterTab name={'Home'} path={'/'}>
            <Home/>
        </FooterTab>
        <FooterTab name={'Get Link'} path={'/link'}>
            <Link/>
        </FooterTab>
        <FooterTab name={'Settings'} path={'/settings'}>
            <Settings/>
        </FooterTab>
    </StyledFooter>;
