import React from "react";
import styled from 'styled-components';
import {Link, useLocation} from "react-router-dom";
import breakpoint from 'styled-components-breakpoint';

const StyledFooterTab = styled.div`
    padding: 0.5em 1em;
    text-align: center;
    max-width: 6em;
    width: 100%;
    opacity: 0.5;
    color: var(--text-color-normal);
    
    ${breakpoint('tablet')`
        padding: 0.5em 4em;
    `}
    
    &.active {
        opacity: 1;
    }
    
    p {
        margin: 0;
    }
`;

export const FooterTab = ({path, name, children}) => {
    const location = useLocation();
    let isSelected = location.pathname === path ||
        (location.pathname.includes("instagram.com") && path === '/link');
    return (
        <Link to={path}>
            <StyledFooterTab className={isSelected ? 'active' : ''}>
                {children}
                <p>{name}</p>
            </StyledFooterTab>
        </Link>
    )
}
